





















import { Vue, Component } from 'vue-property-decorator'
import { ColumnConfig } from '@/components/table/types'
@Component
export default class community extends Vue {
  tableData = [];
  total = 0;
  loading = false;
  formInline = {
    region: ''
  };

  current = 1;

  // onSearch (): void {
  //   this.current = 1
  //   this.fetch()
  // }

  // onReSet () {
  //   this.formInline = {
  //     region: ''
  //   }
  //   this.fetch()
  // }

  column: Array<ColumnConfig> = [
    {
      label: '名称',
      code: 'name'
    },
    {
      label: '所在省/市/区',
      code: 'address'
    },
    {
      label: '众筹人数',
      code: 'driveCount'
    },
    {
      label: '建立众筹日期',
      code: 'createTime'
    },
    {
      label: '操作',
      key: 'action',
      options: [
        {
          title: '开通',
          click: (row: any) => {
            this.updateCommunity(row.id, 3)
          }
        },
        {
          title: '删除',
          click: (row: any) => {
            this.updateCommunity(row.id, 0)
          }
        }
      ]
    }
  ];

  async updateCommunity (id: number, type: number) {
    if (type === 0) {
      await this.$confirm('此操作将删除该圈子, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      await this.$Post(this.$Api.updateCommunity, {
        id,
        type
      })
      this.fetch()
      this.$message({
        type: 'success',
        message: '删除成功!'
      })
    } else {
      await this.$Post(this.$Api.updateCommunity, {
        id,
        type
      })
      this.fetch()
    }
  }

  changePagination (current: any):void {
    this.current = current
    this.fetch()
  }

  async fetch () {
    this.loading = true
    const r = await this.$Get(this.$Api.notOpencommunity, {
      pageSize: 10,
      current: this.current
    })
    this.tableData = r.data.records
    this.total = r.data.total
    this.loading = false
  }

  mounted () {
    this.fetch()
  }
}
